"use client";

import cn from "clsx";
import { ReactNode, useRef, useState } from "react";

import { useOnClickOutside } from "@/common/hooks/use-click-outside";
import { CaretIcon } from "@/common/icons/";

type DivElementRef = React.MutableRefObject<HTMLDivElement>;

type DropdownMenuProps = {
  label?: string;
  icon?: ReactNode;
  children: ReactNode;
};

export function DropdownMenu({ label, icon, children }: DropdownMenuProps) {
  const DropdownMenuRef = useRef() as DivElementRef;
  const [isopen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const toggleMenu = () => setIsOpen(!isopen);
  useOnClickOutside(DropdownMenuRef, closeMenu);

  return (
    <div ref={DropdownMenuRef} className="text-grey-90 relative">
      <div
        onClick={toggleMenu}
        className="flex h-8 cursor-pointer select-none items-center justify-between px-1"
      >
        {!!label && (
          <div className="flex items-center justify-between">
            <span className="text[15px] pr-2 font-semibold uppercase">{label}</span>
            <div className={cn("mt-[2px]", { "rotate-180": isopen })}>
              <CaretIcon />
            </div>
          </div>
        )}
        {icon}
      </div>
      {isopen && (
        <ul
          onClick={closeMenu}
          className="border-grey-40 absolute right-0 z-50 min-w-[200px] overflow-hidden rounded-lg border bg-white text-sm shadow-[6px_6px_8px_0px_rgba(0,0,0,0.08)]"
        >
          {children}
        </ul>
      )}
    </div>
  );
}
