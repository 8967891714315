"use client";

import { useTranslations } from "next-intl";

import Button from "@/common/components/button";

type ScrollToButtonProps = {
  elementId: string;
  label?: string;
  labelKey?: string;
  className?: string;
};

export function ScrollToButton({ label, labelKey, elementId, className }: ScrollToButtonProps) {
  const t = useTranslations();
  return (
    <Button
      variant="secondary"
      className={className}
      onClick={() => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }}
    >
      {label || t(labelKey)}
    </Button>
  );
}
